
/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer{
	position: relative;
	z-index: 2;
	// background:#000; 	
	// height:80px; 
	text-align:left;
	width:100%;
	min-width: $min-width;
	order: 3;	
    -webkit-order: 3;	
}

.social {
	padding: 13px;
	p {
		padding-bottom: 5px;
		font-family: $custom-font-family;
		color: #fff;
		font-size: 22px;
		font-weight: 400;
	}
}

.social__link {
	display: inline-block;
	margin-right: 27px;
	
	&:hover {
		opacity: 0.85;
	}
	
	img {
		vertical-align: bottom;
	}
}

.footer__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.nav__list {
	display: flex;
	list-style: none;
	background-color: #fff;
	border-top-left-radius: 5px; 
	
	li {
		padding: 12px 13px 6px 13px;
	}
}

.nav__link {
	font-family: $custom-font-family;
	color: #000;
	font-size: 22px;
	
	&:hover {
		opacity: 0.8;
	}
}

@media screen and (-ms-high-contrast: active) and (min-width: 992px), (-ms-high-contrast: none) and (min-width: 992px) {
	.footer {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
	}
                           // / для IE10 IE11 /
}

@media screen and (max-width: 991px) {

	.footer__wrapper {
		flex-wrap: wrap;
	}
	
	.footer__menu {
		width: 100%;
	}
	
	.nav__list {
		justify-content: center;
		flex-wrap: wrap;
		border-radius: 0;
	}
}

@media screen and (max-width: 767px) {
	.nav__list {
		padding-top: 18px;
		li {
			padding-top: 0;
			padding-bottom: 12px;
		}
	}
	
	.social {
    padding-bottom: 28px;
	}
}

@media screen and (max-width: 480px) {
	.nav__list {
		li {
			width: 100%;
			text-align: center;
		}
	}
	
	.social {
		margin: 0 auto;
		text-align: center;
	}
	
	.social__link:last-child {
		margin-right: 0;
	}
}