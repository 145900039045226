
/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header{
	width:100%; 
	height: 80px;
	min-width: $min-width;
	text-align:left;	
	order: 1;	
    -webkit-order: 1;	
}

@media screen and (max-width: 480px) {
	header {
		height: 50px;
	}
}
